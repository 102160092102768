// https://mrcoles.com/maximum-delay-settimeout/

const MAX_32_BIT = 0x7fffffff;

export function safeTimeout(
  onTimeout: () => void,
  delay: number,
  clearHelper?: { timeoutId: number }
) {
  const timeoutId = window.setTimeout(() => {
    if (delay > MAX_32_BIT) {
      return safeTimeout(onTimeout, delay - MAX_32_BIT, clearHelper);
    } else {
      onTimeout();
    }
  }, Math.min(MAX_32_BIT, delay));

  if (clearHelper) {
    clearHelper.timeoutId = timeoutId;
  }
}
