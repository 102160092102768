import type { AppProps } from "next/app";

import React, { useEffect } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Script from "next/script";
import { Session } from "next-auth";
import { SessionProvider, useSession } from "next-auth/react";

import { UserSessionKey } from "@/cross-platform-resources/chrome-extension/ChromeStorageKey";
import { safeTimeout } from "@/utils/Timeout";

import "../styles/fonts/wotfard.scss";
import "../styles/fonts/wotfard-italic.scss";
import "../styles/globals.scss";

export const queryClient = new QueryClient();

const updateExtensionMargin = 30 * 1000; // 30 seconds in miliseconds;
const refreshTokenAheadOfTime = 30 * 60 * 1000; // 30 minutes in miliseconds
export const allowRefreshTokenTimeFrame = 60 * 60 * 1000; // 60 minutes in miliseconds

const RefreshTokenHandler = () => {
  const { data: session, update } = useSession();

  useEffect(() => {
    const refreshTokenTimeout = { timeoutId: -1 };
    const updateExtensionTimeout = { timeoutId: -1 };

    if (session) {
      // https://dev.to/mabaranowski/nextjs-authentication-jwt-refresh-token-rotation-with-nextauthjs-5696

      /*
        We set the token to be able to refresh ahead of time 1 hour in [...nextauth].ts.
        Here we set interval to renew ahead of time 30 minutes -> This way, access token can be surely renewed.
      */
      const timeRemaining =
        session.accessTokenExpiresAt - refreshTokenAheadOfTime - Date.now();

      safeTimeout(
        () => {
          update();
        },
        timeRemaining,
        refreshTokenTimeout
      );

      // Post message to Chrome extension
      safeTimeout(
        () => {
          // https://github.com/nextauthjs/next-auth/discussions/6021
          window.postMessage(
            {
              purpose: "Yoyo Shortcut User Session",
              [UserSessionKey]: session,
            },
            process.env.NEXT_PUBLIC_FRONTEND_HOST as string
          );
        },
        timeRemaining + updateExtensionMargin, // Allow extra 30 seconds for next-auth to finish fetching a new access token
        updateExtensionTimeout
      );
    }

    return () => {
      window.clearTimeout(refreshTokenTimeout.timeoutId);
      window.clearTimeout(updateExtensionTimeout.timeoutId);
    };
  }, [JSON.stringify(session)]);

  return null;
};

type Props = {
  session: Session;
};

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<Props>) {
  // Mount
  useEffect(() => {
    // Mobile bug with 100vh: https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
    getAppHeight();
    window.addEventListener("resize", getAppHeight);
  }, []);

  // Unmount
  useEffect(
    () => () => {
      window.removeEventListener("resize", getAppHeight);
    },
    []
  );

  const getAppHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--height-app", `${window.innerHeight}px`);
  };

  return (
    <>
      {/* https://app.termly.io/dashboard/website/.../banner-settings */}
      <Script
        type="text/javascript"
        src="https://app.termly.io/embed.min.js"
        data-auto-block="on"
        data-website-uuid="7380ceb4-9386-4693-babc-ca88eb8a5963"
      />

      <SessionProvider
        session={session}
        refetchOnWindowFocus={false}
        refetchWhenOffline={false}
      >
        <RefreshTokenHandler />

        <Script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}`}
        />

        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ""}
        >
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />

            {/* Main app */}
            <Component {...pageProps} />
          </QueryClientProvider>
        </GoogleOAuthProvider>
      </SessionProvider>
    </>
  );
}

export default MyApp;
